<template>
    <div class="col-lg-3 col-md-4 col-sm-6">
        <router-link :to="{ name: 'DetailMBKM', params: {code: data.slug } }">
        <div class="gallery-items card mb-4">
            <img :src="data.images" alt="PREVIEW">
            <h3>{{ data.title }}</h3>
        </div>
        </router-link>
    </div>
</template>
<script>
    export default {
        name: 'card_gallery',
        props: ['data']
    }
</script>
