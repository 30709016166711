<template>
    <div id="mbkm">
        <transition name="slide-fade" mode="out-in">
        <div class="mcontainer" v-if="this.$route.name === 'MBKM' ">
            <div class="md:flex justify-between relative md:mb-4 mb-3">
                <div class="flex-1">
                    <h2 class="text-lg font-semibold">MBKM</h2>
                    <p>Portal Merdeka Belajar - Kampus Merdekan Infinity PSM FBE UBAYA</p>
                    <br>
                </div>
                <router-link to="/app/mbkm/create" v-if="datauser.is_admin === 1" class="flex d-inline items-center justify-center px-5 py-2 rounded-md bg-blue-600 text-white md:absolute right-0">
                    <span class=""> Create MBKM </span>
                </router-link>
            </div>
            <transition name="fade" mode="out-in">
                <div class="row mt-5" v-if="loading">
                    <CardGallery v-for="gallery in galleries" :key="gallery.id" :data="gallery" />
                </div>
                <div class="row mt-5" v-if="!loading">
                    <content-placeholders v-for="index in 8" :key="index" class="col-md-3 my-3" :centered="true">
                        <content-placeholders-img />
                        <content-placeholders-text :lines="1" />
                    </content-placeholders>
                </div>
            </transition>
        </div>
        <router-view/>
        </transition>
    </div>
</template>
<script>
    import { mapState } from 'vuex'
    import CardGallery from '@/components/CardGallery'
    import axios from 'axios'

    export default {
        name: 'MBKM',
        computed: mapState('auth', { datauser: 'user' }),
        components: {
            CardGallery
        },
        data () {
            return {
                galleries: [],
                loading: false
            }
        },
        created () {
            this.fetchData()
        },
        watch: {
            $route: 'fetchData'
        },
        methods: {
            async fetchData () {
                this.loading = false
                try {
                    const res = await axios.get('mbkm')
                    this.galleries = res.data.result
                    this.loading = true
                } catch (e) {
                    console.error(e)
                }
            }
        }
    }
</script>
